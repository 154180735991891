@font-face {
  font-family: 'Cookie';
  src: url('./Cookie-Regular.ttf') format('truetype');
  font-weight: 'normal';
  font-style: 'normal';
}

@font-face {
  font-family: 'SRG SSR Type';
  src: url('./SRGSSRType_Rg.ttf') format('truetype');
  font-weight: 'normal';
  font-style: 'normal';
}

@font-face {
  font-family: 'SRG SSR Type serif';
  src: url('./SRGSSRType_Serif_Rg.ttf') format('truetype');
  font-weight: 400;
  font-style: 'normal';
}